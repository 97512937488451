import React from 'react';

export const VisibilityOffIcon = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="16.000000pt"
    height="16.000000pt"
    viewBox="0 0 30.000000 30.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,30.000000) scale(0.100000,-0.100000)"
      fill="#585858"
      stroke="none"
    >
      <path
        d="M10 282 c0 -14 269 -276 276 -269 4 4 -5 20 -20 36 l-27 28 30 34
c17 18 31 37 31 40 0 4 -20 25 -45 47 -50 44 -93 57 -142 43 -27 -8 -34 -6
-59 20 -27 28 -44 37 -44 21z m191 -83 c14 -14 19 -30 17 -52 -4 -38 -18 -40
-36 -3 -8 15 -21 30 -30 33 -26 9 -42 24 -36 34 10 17 64 9 85 -12z"
      />
      <path
        d="M21 176 l-23 -25 21 -29 c25 -36 100 -75 136 -70 39 5 33 22 -10 29
-43 7 -65 30 -65 69 0 24 -16 50 -30 50 -4 0 -17 -11 -29 -24z"
      />
    </g>
  </svg>
);
