import { useLocation } from '@gatsbyjs/reach-router';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';

import { Layout } from '@components/layout';
import { NoIndex, description, title } from '@components/layout/SEO';
import { Reset } from '@features/reset-password';

const ResetPage: FC<PageProps> = () => {
  const search = useLocation().search;
  const query = new URLSearchParams(search);

  return (
    <Layout>
      <Helmet>
        <title>パスワードを忘れた方はこちら | {title}</title>
        <meta name="description" content={description} />
        {NoIndex}
      </Helmet>
      <Reset hash={query.get('hash') || undefined} />
    </Layout>
  );
};

export default ResetPage;
