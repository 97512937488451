import React, { ChangeEventHandler, FC, useState } from 'react';
import styled from 'styled-components';

import { VisibilityIcon } from '@assets/image/svg/VisibilityIcon';
import { VisibilityOffIcon } from '@assets/image/svg/VisibilityOffIcon';
import { ReactComponent as KeyIcon } from '@assets/image/svg/key.svg';
import { Input } from '@components/controls/Input';
import { Button } from '@components/controls/button';
import { Block } from '@components/layout/block';
import { Card } from '@components/layout/card';
import { HStack } from '@components/layout/hstack';
import { VStack } from '@components/layout/vstack';
import { ErrorMessage } from '@components/typographies/error';
import { Heading2 } from '@components/typographies/heading';
import { HelperText } from '@components/typographies/helperText';
import { Paragraph } from '@components/typographies/paragraph';
import { useUpdatePassword } from '@features/reset-password/hooks/useUpdatePassword';
import { useUpdatePasswordForm } from '@features/reset-password/hooks/useUpdatePasswordForm';
import { useDetectMedia } from '@hooks/useDetectMedia';

const PasswordInput: FC<{
  label: string;
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  helperText?: string;
  errorMessage?: string;
}> = ({ label, value, onChange, helperText, errorMessage }) => {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <VStack spacing="5px" align="flex-start" width="100%">
      <Paragraph fontSize="14px" fontWeight="700" lh="normal">
        {label}
      </Paragraph>
      <div style={{ position: 'relative', width: '100%' }}>
        <Input
          borderRadius="5px"
          hasBorder
          value={value}
          onChange={onChange}
          type={isVisible ? 'text' : 'password'}
        />
        <span
          onClick={() => setIsVisible((isVisible) => !isVisible)}
          style={{
            cursor: 'pointer',
            position: 'absolute',
            top: '50%',
            right: '16px',
            transform: 'translateY(-50%)',
          }}
        >
          {isVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </span>
      </div>
      {helperText && <HelperText> {helperText}</HelperText>}
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </VStack>
  );
};

export const UpdatePassword: FC<{ hash: string }> = ({ hash }) => {
  const { updatePassword, error, isLoading } = useUpdatePassword({ hash });
  const { data, handleInputChange, handleSubmit, errors } = useUpdatePasswordForm();
  const { md } = useDetectMedia();

  return (
    <Card
      bg="gray-second"
      height="max-content"
      width={md ? 'max-content' : 'auto'}
      mx={md ? 'auto' : '19px'}
      px={md ? '75px' : '20px'}
      py={md ? '40px' : '20px'}
    >
      <VStack
        spacing={md ? '20px' : '10px'}
        align="flex-start"
        as={'form'}
        onSubmit={handleSubmit(updatePassword)}
      >
        <VStack spacing="0" align="flex-start">
          <HStack spacing="9px">
            <KeyIconWrapper bg="gray-second">
              <KeyIcon />
            </KeyIconWrapper>
            <Heading2 ml="10px">パスワード変更</Heading2>
          </HStack>
        </VStack>
        <Paragraph fontSize="13px" fontWeight="400" lh="normal">
          大文字・小文字のアルファベット、数字を含んだ８文字以上のパスワードを入力してください。
        </Paragraph>
        <PasswordInput
          label={'新しいパスワード'}
          value={data.password}
          onChange={handleInputChange('password')}
          errorMessage={errors.password?._errors[0]}
        />
        <PasswordInput
          label={'新しいパスワード（確認用）'}
          value={data.cfmPassword}
          onChange={handleInputChange('cfmPassword')}
          errorMessage={error?.getError() || errors.cfmPassword?._errors[0]}
        />
        <Block padding="20px 0" bg="gray-second" width="100%">
          <Button
            type="submit"
            width="210px"
            height="35px"
            mx="auto"
            disabled={isLoading}
            color="white"
          >
            {isLoading ? 'Loading...' : 'パスワードを変更する'}
          </Button>
        </Block>
      </VStack>
    </Card>
  );
};

const KeyIconWrapper = styled(Block)`
  width: 27px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
