import React, { FC } from 'react';

import { ReactComponent as KeyIcon } from '@assets/image/svg/key.svg';
import { Input } from '@components/controls/Input';
import { Button } from '@components/controls/button';
import { Card } from '@components/layout/card';
import { Flex } from '@components/layout/flexbox';
import { VStack } from '@components/layout/vstack';
import { ErrorMessage } from '@components/typographies/error';
import { Heading2 } from '@components/typographies/heading';
import { Paragraph } from '@components/typographies/paragraph';
import { useCertification } from '@features/reset-password/hooks/useCertification';
import { useCertificationPasswordForm } from '@features/reset-password/hooks/useCertificationPasswordForm';
import { useDetectMedia } from '@hooks/useDetectMedia';

export const Certification: FC = () => {
  const { certificationPassword, error, isLoading } = useCertification();
  const { data, handleInputChange, handleSubmit, errors } = useCertificationPasswordForm();
  const { md } = useDetectMedia();

  return (
    <Card
      bg="gray-second"
      height="max-content"
      width={md ? 'max-content' : 'auto'}
      mx={md ? 'auto' : '19px'}
      px={md ? '75px' : '20px'}
      py={md ? '40px' : '20px'}
    >
      <VStack
        spacing="20px"
        align="flex-start"
        as={'form'}
        onSubmit={handleSubmit(certificationPassword)}
      >
        <VStack spacing="0" align="flex-start">
          <Flex jc="left">
            <KeyIcon />
            <Heading2 ml="10px">パスワードを忘れた方はこちら</Heading2>
          </Flex>
          <Paragraph lh="normal">
            登録しているメールアドレスにパスワードの再設定URLをお送りいたします。
          </Paragraph>
        </VStack>
        <VStack spacing="10px" align="flex-start" width="100%">
          <Heading2>メールアドレス</Heading2>
          <Input
            placeholder="メールアドレス"
            borderRadius="5px"
            hasBorder
            value={data.email}
            onChange={handleInputChange('email')}
          />
          {error?.getError() && <ErrorMessage>{error.getError()}</ErrorMessage>}
          {errors.email?._errors[0] && <ErrorMessage>{errors.email?._errors[0]}</ErrorMessage>}
        </VStack>
        <Flex jc="center" width="100%">
          <Button type="submit" width="210px" height="35px" disabled={isLoading} color="white">
            {isLoading ? 'Loading...' : 'メールを送信する'}
          </Button>
        </Flex>
      </VStack>
    </Card>
  );
};
