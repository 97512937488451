import { ChangeEventHandler, FormEventHandler, useEffect, useState } from 'react';
import { z, ZodFormattedError } from 'zod';

const Schema = z
  .object({
    password: z
      .string({ required_error: 'パスワードを入力して下さい' })
      .min(8, { message: '8文字以上で入力して下さい' })
      .max(20, { message: '20文字以下で入力して下さい' })
      .regex(
        /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{8,100}$/,
        '半角英小文字大文字数字をそれぞれ1種類以上入力して下さい'
      ),
    cfmPassword: z
      .string({ required_error: 'パスワードを入力して下さい' })
      .min(8, { message: '8文字以上で入力して下さい' })
      .max(20, { message: '20文字以下で入力して下さい' })
      .regex(
        /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{8,100}$/,
        '半角英小文字大文字数字をそれぞれ1種類以上入力して下さい'
      ),
  })
  .superRefine(({ password, cfmPassword }, ctx) => {
    if (password !== cfmPassword) {
      ctx.addIssue({
        path: ['cfmPassword'],
        code: 'custom',
        message: 'パスワードが一致しません',
      });
    }
  });

export const useUpdatePasswordForm = () => {
  const [data, setData] = useState<z.infer<typeof Schema>>({ password: '', cfmPassword: '' });
  const [errors, setErrors] = useState<Partial<ZodFormattedError<z.infer<typeof Schema>>>>({});

  const handleInputChange =
    (path: keyof z.infer<typeof Schema>): ChangeEventHandler<HTMLInputElement> =>
    (event) => {
      setData((prevState) => ({
        ...prevState,
        ...{
          [path]: event.target.value,
        },
      }));
    };

  const handleSubmit =
    (onValid: (data: z.infer<typeof Schema>) => Promise<void>): FormEventHandler =>
    async (event) => {
      event.preventDefault();
      const result = Schema.safeParse(data);
      if (!result.success) {
        setErrors(result.error.format());
      } else {
        await onValid(result.data);
      }
    };

  useEffect(() => {
    if (Object.values(errors).some((errors) => errors !== undefined)) {
      const result = Schema.safeParse(data);
      if (!result.success) {
        setErrors(result.error.format());
      } else {
        setErrors({});
      }
    }
  }, [data]);

  return {
    data,
    handleInputChange,
    handleSubmit,
    errors,
  };
};
