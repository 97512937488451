import { AxiosResponse } from 'axios';
import { navigate } from 'gatsby';
import { useMutation } from 'react-query';

import { axiosClient } from '@api/axiosClient';
import { ErrorResponse } from '@pb/api/error_pb';
import { UpdateRequest, UpdateResponse } from '@pb/api/v1/user/password/password_pb';

const updatePassword = async ({ hash, password, cfmPassword }: UpdateRequest.AsObject) => {
  const request = new UpdateRequest();
  request.setHash(hash);
  request.setPassword(password);
  request.setCfmPassword(cfmPassword);

  await axiosClient
    .post('/api/v1/user/password/update', request.serializeBinary())
    .then((res: AxiosResponse<Uint8Array>) => {
      const { data } = res;
      return UpdateResponse.deserializeBinary(data).toObject();
    });
};

export const useUpdatePassword = ({ hash }: { hash: string }) => {
  const { mutateAsync, error, isLoading } = useMutation<
    void,
    ErrorResponse,
    Omit<UpdateRequest.AsObject, 'hash'>
  >(
    async ({ password, cfmPassword }: Omit<UpdateRequest.AsObject, 'hash'>) =>
      await updatePassword({
        hash,
        password,
        cfmPassword,
      }),
    {
      onSuccess: () => navigate('/reset-password/complete'),
    }
  );
  return {
    updatePassword: mutateAsync,
    error,
    isLoading,
  };
};
